<template>
  <div>
    <div class="head_section">
      <h2 id="dkBody" class="tit_subdesc">
        <span class="txt_number">구매요청번호 : {{ docNo }}</span>
        <span class="txt_info">{{ _.unescape(model.title) }}</span>
      </h2>
    </div>

    <div class="section_g">
      <PayStatus :approval-line="approvalLine" :doc-no="docNo" :doc-flag="'DI'" />
    </div>

    <DraftPurchaseReqeustInfo
      :model.sync="model"
      @onClickSearchProjectCode="onClickSearchProjectCode"
    />

    <!-- 구매요청품목 -->
    <DraftPurchaseGoodsList
      v-if="model.assetType === assetType.INFRA_ASSET"
      :model.sync="model"
      @onClickSearchGoodsName="onClickSearchGoodsName"
      @onClickSearchProjectCode="onClickSearchProjectCode"
    />

    <DraftPurchasePayment :model.sync="model" />

    <DraftFile :attachFileList.sync="model.attachFileList" />

    <div class="wrap_btn type_bottom">
      <div class="pos_left" />
      <div class="pos_right">
        <button
          v-if="isNotSuperOrAdmin"
          type="button"
          class="btn_large btn_primary"
          @click.prevent="popUpApproveSaveLayer"
        >
          저장
        </button>
        <button type="button" class="btn_large btn_primary" @click.prevent="popUpApproveLayer">
          재합의
        </button>
      </div>
    </div>

    <PopGoodsNameSearch
      v-if="isPopGoodsNameSearch"
      :selectedGoodsName="selectedGoodsName"
      @onClickClose="onClickClosePopGoodsNameSearch"
      @onClickComplete="onClickCompletePopGoodsNameSearch"
    />
    <PopProjectCodeSearch
      v-if="isPopProjectCodeSearch"
      :selectedProject="selectedProject"
      @onClickClose="onClickClosePopProjectCodeSearch"
      @onClickComplete="onClickCompletePopProjectCodeSearch"
    />
  </div>
</template>

<script>
import PurchaseRequestDetailMixin from "@/_approval/components/shared/view/PurchaseRequestDetailMixin";
import commDocs from "@/_approval/mixins/common/commDocs";
import DraftWriteModel from "@/_approval/pages/purchaseDraft/write/DraftWriteModel";
import {
  approvalStatus,
  aprvlLineType,
  assetType,
  docStatus,
  targetGubun,
} from "@/_approval/const/const";
import PayStatus from "@/_approval/components/shared/view/PayStatus";
import RejectComment from "@/_approval/components/shared/view/RejectComment";
import DraftPurchaseReqeustInfo from "@/_approval/components/purchaseDraft/write/DraftPurchaseReqeustInfo";
import DraftPurchaseGoodsList from "@/_approval/components/purchaseDraft/write/DraftPurchaseGoodsList";
import DraftPurchasePayment from "@/_approval/components/purchaseDraft/write/DraftPurchasePayment";
import DraftFile from "@/_approval/components/purchaseDraft/write/DraftFile";
import PopGoodsNameSearch from "@/_approval/components/purchaseDraft/write/popup/PopGoodsNameSearch";
import PopProjectCodeSearch from "@/_approval/components/purchaseDraft/write/popup/PopProjectCodeSearch";
import ApiService from "@/services/ApiService";
import commWriteDoc from "@/_approval/mixins/common/commWriteDoc";

import LocalStorageManager from "@/LocalStorageManager";

export default {
  name: "DraftRejectEdit",
  components: {
    PayStatus,
    RejectComment,
    DraftPurchaseReqeustInfo,
    DraftPurchaseGoodsList,
    DraftPurchasePayment,
    PopGoodsNameSearch,
    PopProjectCodeSearch,
    DraftFile,
  },
  mixins: [PurchaseRequestDetailMixin, commDocs, commWriteDoc],
  data() {
    return {
      draftData: {},
      approvalLine: [],
      model: new DraftWriteModel(),
      assetType: assetType,
      projectCodeTarget: { type: "", index: null }, // 기안금액 info, 구매요청품목 goods 구분
      isPopGoodsNameSearch: false,
      selectedGoodsName: {},
      isPopProjectCodeSearch: false,
      goodsNameTargetIndex: null,
      selectedProject: {},
    };
  },
  computed: {
    isNotSuperOrAdmin() {
      return (
        LocalStorageManager.shared.getIsInspect() ||
        LocalStorageManager.shared.getIsRollUser() ||
        LocalStorageManager.shared.getIsRollUser().getIsPartner
      );
    },
  },
  async mounted() {
    const data = await this.fetchStoredDraftData(this.docNo);

    this.model.initFromFetchData(data);
    this.model.targetPk = this.docNo;
    this.model.deptPathName = await this.fetchDeptPathNameByDeptCode(data.drftDeptCode);
    this.approvalLine = data.aprvlLineList;
  },
  methods: {
    popUpApproveLayer() {
      this._showLayer({ type: "confirm", content: "해당 기안을 재합의하시겠습니까?" }, () => {
        this.approveDraft(approvalStatus.APPROVAL_PAYMENT, "기안승인 되었습니다.");
      });
    },
    async approveDraft() {
      this.model.processingApprovalLine = this.getApprovalLineFilteredByType(
        aprvlLineType.APPROVAL,
      );

      const errorMsg = this.model.validate();
      if (errorMsg) {
        this._showLayer({ type: "alert", content: errorMsg });
        return;
      }

      const formData = this.model.makeFormData();
      const res = await ApiService.shared.post(this.$apiPath.APRVL_LINE_PROC, {
        aprvlStatus: approvalStatus.APPROVAL_PAYMENT,
        targetGubun: targetGubun.STANDARD,
        targetPk: this.docNo,
        data: formData.data,
      });

      if (parseInt(res.code) !== 200) {
        this._showLayer({
          type: "alert",
          content: res.text || "승인에 실패했습니다. 관리자에게 문의하세요",
        });
        return;
      }

      if (!this.checkNormalResponse(res)) {
        return;
      }

      await this.$router.push(this.$routerPath.PAYMENT_DRAFT_ING);
    },
    popUpApproveSaveLayer() {
      this._showLayer({ type: "confirm", content: "저장 하시겠습니까?" }, () => {
        this.saveDraft(approvalStatus.APPROVAL_PAYMENT, "기안승인 되었습니다.");
      });
    },
    async saveDraft() {
      this.model.processingApprovalLine = this.getApprovalLineFilteredByType(
        aprvlLineType.APPROVAL,
      );

      const errorMsg = this.model.validate();
      if (errorMsg) {
        this._showLayer({ type: "alert", content: errorMsg });
        return;
      }

      const formData = this.model.makeFormData();
      const res = await ApiService.shared.put(`${this.$apiPath.APRVL_DOC_DETAIL}`, {
        targetGubun: targetGubun.STANDARD,
        aprvlLineList: formData.aprvlLineList,
        targetPk: this.docNo,
        data: formData.data,
        docStatus: docStatus.PROCESSING,
      });

      if (parseInt(res.code) !== 200) {
        this._showLayer({
          type: "alert",
          content: res.text || "승인에 실패했습니다. 관리자에게 문의하세요",
        });
        return;
      }

      if (!this.checkNormalResponse(res)) {
        return;
      }

      await this.$router.push(this.$routerPath.PAYMENT_DRAFT_ING);
    },
  },
};
</script>
