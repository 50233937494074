<template>
  <div class="blank_asset5">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text type_outside">구매요청 기본정보</strong>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <caption class="ir_caption">
          구매요청 기본정보 표
        </caption>
        <colgroup>
          <col style="width: 192px" />
          <col />
          <col style="width: 192px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>구매요청명 <sup>*</sup></th>
            <td colspan="3">
              <Input :value.sync="model.title" :maxLength="100" />
            </td>
          </tr>
          <tr>
            <th>구매요청 번호</th>
            <td>{{ model.targetPk }}</td>
            <th>구매요청 등록일</th>
            <td>{{ model.regDate }}</td>
          </tr>
          <tr>
            <th>구매요청자</th>
            <td>{{ this.me.accountId }}({{ this.me.name }})</td>
            <th>구매요청부서</th>
            <td>{{ model.deptPathName }}</td>
          </tr>
          <tr>
            <th>구매 구분 <sup>*</sup></th>
            <td>
              <comm-opt
                :classList="['type_medium']"
                :optionDesc="'구분 선택상자'"
                :optionName="assetTypeName"
                :optionList="assetTypeList"
                :eventName="'select'"
                @select="selectAssetType"
              />
            </td>
            <th>구매 카테고리</th>
            <td>
              <Search
                dropBoxMaxHeight="162px"
                placeholder="구매 카테고리 검색"
                :icon="true"
                :dataList="handleDataList"
                :value.sync="model.itemTitle"
                @setValue="onSetValue"
                @onInputKeyword="onInputKeyword"
              />
            </td>
          </tr>
          <tr>
            <th scope="row">상세내용</th>
            <td colspan="3" style="height: 240px">
              <Textarea style="height: 240px" :max="300" :value.sync="model.contents" />
            </td>
          </tr>
          <tr v-if="model.purchasePriceList.length === 1 && model.assetType == 'H'">
            <th>기안금액 <sup>*</sup></th>
            <td>
              <div class="row_pack">
                <div class="area_full_r">
                  <button type="button" class="btn_small btn_fourthly" @click="onClickAddPrice">
                    추가
                  </button>
                </div>
                <div class="area_full">
                  <InputAutoMoney
                    :maxLength="100"
                    :value.sync="model.purchasePriceList[0].draftingPrice"
                  />
                </div>
              </div>
            </td>
            <th>프로젝트 코드 <sup>*</sup></th>
            <td>
              <div class="row_pack">
                <div class="area_full_r">
                  <button
                    type="button"
                    class="btn_small btn_fourthly"
                    @click="$emit('onClickSearchProjectCode', 'info', 0)"
                  >
                    조회
                  </button>
                </div>
                <div class="area_full">
                  <Input
                    :value.sync="model.purchasePriceList[0].projectCode"
                    :isDisabled="true"
                    :maxLength="100"
                  />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="model.purchasePriceList.length > 1 && model.assetType == 'H'"
      class="tbl_comm tbl_list"
    >
      <table>
        <colgroup>
          <col style="width: 204px" />
          <!-- No -->
          <col style="width: 344px" />
          <!-- 기안금액 -->
          <col />
          <!-- 프로젝트코드 -->
          <col style="width: 198px" />
          <!-- 추가/삭제 -->
        </colgroup>
        <thead>
          <tr>
            <th class="align_left">No.</th>
            <th>기안금액</th>
            <th>프로젝트코드</th>
            <th class="align_right">
              <button type="button" class="btn_small btn_secondary" @click="onClickAddPrice">
                추가
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in model.purchasePriceList" :key="index">
            <td class="align_left">
              {{ index + 1 }}
            </td>
            <td>
              <InputAutoMoney :maxLength="100" :value.sync="item.draftingPrice" />
            </td>
            <td>
              <div class="row_pack">
                <div class="area_full_r">
                  <button
                    type="button"
                    class="btn_small btn_fourthly"
                    @click="$emit('onClickSearchProjectCode', 'info', index)"
                  >
                    조회
                  </button>
                </div>
                <div class="area_full">
                  <Input :value.sync="item.projectCode" :isDisabled="true" :maxLength="100" />
                </div>
              </div>
            </td>
            <td class="align_right">
              <button
                type="button"
                class="btn_small btn_fourthly"
                @click="onClickRemovePrice(item)"
              >
                삭제
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="model.assetType == 'H'" class="tbl_foot">
      <div class="area_total">
        <dl>
          <dt>총 기안금액 <span class="txt_info">(부가세 제외)</span></dt>
          <dd>{{ totalDraftingPrice }}</dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import CommOpt from "@/_approval/components/common/CommOpt";
import Input from "@/_approval/components/common/input/Input";
import InputAutoMoney from "@/_approval/components/common/input/InputAutoMoney";
import Search from "@/_approval/components/common/search/Search";
import Textarea from "@/_approval/components/common/textarea/Textarea";
import { mapState } from "vuex";
import LocalStorageManager from "@/LocalStorageManager";
import { assetType } from "@/_approval/const/const";
// import CommKeditor from "@/components/common/CommKeditor";
import ApiService from "@/services/ApiService";
import commDocs from "@/_approval/mixins/common/commDocs";
import { isEmpty, unescape } from "lodash";
import { decodeHTMLEntities } from "@/utils/stringUtils";

export default {
  name: "DraftPurchaseReqeustInfo",
  components: {
    CommOpt,
    InputAutoMoney,
    Input,
    Search,
    Textarea,
    // CommKeditor
  },
  mixins: [commDocs],
  props: {
    model: Object,
  },
  data() {
    return {
      assetTypeList: [
        // { code: assetType.GENERAL_ASSET, name:'총무자산' },
        { code: assetType.SERVICE_AND_GENERAL_ASSET, name: "일반구매" },
        { code: assetType.INFRA_ASSET, name: "자산구매" },
      ],
      originHandleDataList: [],
      handleDataList: [
        "취급품목1",
        "취급품목2",
        "취급품목3",
        "취급품목4",
        "취급품목5",
        "취급품목6",
        "취급품목7",
      ],
      me: {
        typeMe: true,
        empNo: "",
        empName: "",
        accountId: "",
        deptName: "",
        deptCode: "",
        actorFlag: "D",
        actorState: "1",
        apprLineFlag: "1",
      },
    };
  },
  computed: {
    ...mapState("CommStore", {
      login: (state) => state.login,
    }),
    assetTypeName() {
      const assetType = this.assetTypeList.find((item) => item.code === this.model.assetType);
      if (!assetType) return "";

      return assetType.name;
    },
    totalDraftingPrice() {
      return this.model.totalDraftingPrice();
    },
  },
  watch: {
    totalDraftingPrice() {
      this.model.setUnitformRate();
    },
  },
  beforeDestroy() {
    const summernote = $("#summernote_ex");
    // if( summernote )  summernote.summernote('destroy');
  },
  async mounted() {
    // this.createSummernote({
    //   height: 200
    // });

    const userData = LocalStorageManager.shared.getUserData();
    this.me.accountId = userData.username;
    this.me.empNo = userData.empNo;
    this.me.name = userData.empName || userData.name;
    this.me.empName = "";
    this.me.deptName = "";
    this.me.deptCode = "";

    await this.fetchBuyCategory();

    // const note = '1)&nbsp;이동계획&nbsp;포함유무&nbsp;:&lt;br /&gt;2)&nbsp;안건&nbsp;:&nbsp;&lt;br /&gt;3)&nbsp;구매&nbsp;상세&nbsp;내용';
    // const noteDecoded = decodeHTMLEntities( document, note );
    // $('#summernote').summernote('code', noteDecoded);

    // this.model.contents = '1)&nbsp;이동계획&nbsp;포함유무&nbsp;:&lt;br /&gt;2)&nbsp;안건&nbsp;:&nbsp;&lt;br /&gt;3)&nbsp;구매&nbsp;상세&nbsp;내용';
    this.model.contents = "1) 이동계획 포함유무 :\n\n 2) 안건 : \n\n3) 구매 상세 내용 : ";

    this.model.title = this.unescape(this.model.title);
  },

  methods: {
    unescape,
    async fetchBuyCategory() {
      const res = await ApiService.shared.getData(this.$apiPath.HANDLE_ITEM);
      if (!this.checkNormalResponse(res)) {
        return;
      }

      if (res.data && res.data.length === 0) {
        this.originHandleDataList = [];
        return;
      }

      this.originHandleDataList = res.data;
      this.handleDataList = res.data.map((e) => e.itemTitle);
    },
    onFocusout(e) {
      e.target.classList.remove("error");
    },
    selectAssetType(obj) {
      this.model.resetData();
      this.model.assetType = obj.code;
    },
    onSetValue(item) {
      this.model.itemTitle = item;

      this.model.handleNum = this.originHandleDataList
        .filter((e) => e.itemTitle === item)
        .map((e) => e.handleNum)
        .pop();
    },
    onInputKeyword(value) {
      this.handleDataList = this.originHandleDataList
        .filter((e) => e.itemTitle.includes(value))
        .map((e) => e.itemTitle);
    },
    onClickAddPrice() {
      this.model.addPrice();
    },
    onClickRemovePrice(itemToRemove) {
      this.model.removePrice(itemToRemove);
    },
    createSummernote(options) {
      const summernote = $("#summernote");

      if (!summernote) return;

      const self = this;

      var objBasic = {
        maximumImageFileSize: 1048576, // 1메가.
        callbacks: {
          onImageUploadError: function (msg) {
            // upload image to server and create imgNode...
            self.alert(msg);
          },
        },
      };

      if (options) {
        Object.assign(objBasic, options);
      }

      summernote.summernote(objBasic);
    },
  },
};
</script>
